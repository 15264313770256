<template>
    <div
        class="input-choice"
        :class="{ 'side-by-side-layout': ['circle', 'heavier', 'square'].includes(input.name) }"
    >
        <label
            v-if="['circle', 'heavier', 'square'].includes(input.name)"
            class="choice-label side-by-side-label"
        >
            {{ input.label }}
        </label>
        <div
            v-for="(choice, choiceIndex) in input.choices"
            :key="`${input.name}-choice-${choiceIndex}`"
            class="choice-option"
        >
            <input
                type="radio"
                class="radioButtons"
                :id="`${input.name}-choice-${choiceIndex}`"
                :name="input.name"
                :value="choice.value || choice"
                v-model="radioSelection"
                @change="emitRadioChange"
            />
            <label :for="`${input.name}-choice-${choiceIndex}`" class="choice-label">
                {{ choice.label || choice }}
            </label>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
    props: {
        input: {
            type: Object,
            required: true
        },
        modelValue: {
            type: [String, Number, Object],
            default: ''
        }
    },
    setup(props, { emit }) {
        const { input } = toRefs(props);

        const valueNode = input.value?.value;
        let currValue = null;

        if (valueNode) {
            currValue = valueNode.selectedOption || props.modelValue;
        }

        const radioSelection = ref(currValue);

        function emitRadioChange() {
            const value = radioSelection.value;
            emit('input-changed');

            console.log(`Radio Change - Name: ${input.value.name}, Value: ${value}`);
            input.value.value.selectedOption = value;
            
            this.$emit('score-added', input.value.scoreLabel, value);

            console.log(`ScoreLabel: ${input.value.scoreLabel}, Selected Score: ${value}`);
        }

        return {
            radioSelection,
            emitRadioChange
        };
    }
});
</script>

<style scoped>
.choice-option input[type="radio"] {
    margin-right: 8px;
    vertical-align: middle;
}

.input-choice {
    display: flex;
    flex-direction: column;
}

.side-by-side-layout {
    display: flex;
    margin-right: 20px;
    width: 175px;
}

.side-by-side-label {
    margin-right: 12px;
}

.choice-option {
    display: flex;
    align-items: center;
}
</style>
