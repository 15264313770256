<template>
  <div>
    <CASIPreQuestions :preQuestions="jsonData.prequestions" @input-changed="handleInputChanged" />

    <div class="heading">Questions</div>
    <div v-for="(section, sectionIndex) in jsonData.sections" :key="'section' + sectionIndex" class="section">
      <div v-if="section.instructions" class="instructions">
        <span v-for="(instruction, index) in section.instructions" :key="'instruction' + sectionIndex + '-' + index">
          {{ instruction }}
        </span>
      </div>
      <CASIQuestionsSection :questions="section.questions" @score-added="handleScoreAdded" @input-changed="handleInputChanged" />
    </div>

    <div v-for="(section, sectionIndex) in additionalSections" :key="'additional-section' + sectionIndex" class="section">
      <div>Re-ask question #2 if participant answered incorrectly the first time.</div>
      <CASIQuestionsSection :questions="section.questions" @score-added="handleScoreAdded" @input-changed="handleInputChanged" />
    </div>

    <CASIPostQuestions :postQuestions="jsonData.postquestions" @input-changed="handleInputChanged" />
  </div>
</template>

<script>
import CASIPreQuestions from './CASIPrequestions.vue';
import CASIPostQuestions from './CASIPostquestions.vue';
import CASIQuestionsSection from './CASIQuestionsSection.vue';

export default {
  props: {
    jsonData: {
      type: Object,
      required: true,
    },
  },
  components: { 
    CASIPreQuestions, CASIPostQuestions, CASIQuestionsSection 
  },
  methods: {
    handleScoreAdded(scoreLabel, value) {
      this.$emit('score-added', scoreLabel, value);
    },
    handleInputChanged() {
      this.$emit('input-changed');
    }
  },
  computed: {
    additionalSections() {
      return this.jsonData.sections.filter(section => 
        section.questions.some(question => question.number === '2')
      );
    }
  }
};
</script>

<style scoped>
.heading {
  font-size: 28px;
    margin-bottom: 24px;
    color: #237091;
}
</style>
