<template>
  <BaseLayout :heading="'Clock Drawing'" />

  <div v-if="isScoring">
    <ClockDrawingScoring />
  </div>
  <div v-else>
    <ClockDrawingAdminister />
  </div>

  <NotesSection 
    :administeringNotes="jsonData ? jsonData.administeringNotes : ''" 
    :scoringNotes="jsonData ? jsonData.scoringNotes : ''" 
    ref="notesSection" />

  <TextButton buttonText="Submit" :dark="true" type="submit" @click="submitForm" />
  
  <NotificationPopup 
    :message="notificationMessage" 
    :visible="notificationVisible"
  />

  <ConfirmationPopup
    :visible="showConfirmPopup"
    @confirm="handleConfirmLeave"
    @noSave="handleNoSaveLeave"
    @cancel="showConfirmPopup = false"
  />
</template>

<script>
import BaseLayout from './BaseLayout.vue';
import NotesSection from '../components/NotesSection.vue';
import ClockDrawingAdminister from '../components/Pages/ClockDrawingAdminister.vue';
import ClockDrawingScoring from '../components/Pages/ClockDrawingScoring.vue';
import TaskController from '../controllers/TaskController.js';
import ServerCodeModel from '../models/ServerCodeModel';
import TextButton from '@/components/TextButton.vue';
import NotificationPopup from '@/components/NotificationPopup.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';

export default {
  components: {
      BaseLayout,
      NotesSection,
      ClockDrawingAdminister,
      ClockDrawingScoring,
      TextButton,
      NotificationPopup,
      ConfirmationPopup
  },
  computed: {
    isScoring() {
        return this.$route.query.selection === 'score';
    }
  },
  data() {
    return {
      controller: null,
      jsonData: null,
      notificationMessage: '',
      notificationVisible: false,
      showConfirmPopup: false,
      nextRoute: null,
      forceNavigate: false
    };
  },
  async mounted() {
    const serverCodeModel = new ServerCodeModel();
    serverCodeModel.logType = 'InterviewerClockDrawingLog';

    this.controller = new TaskController(serverCodeModel.logType, 'clockDrawingData', serverCodeModel);

    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.controller.loadData();
      this.jsonData = this.controller.model.data;
    },
    async submitForm() {
      this.notificationMessage = 'Saving...';
      this.notificationVisible = true;

      if(this.jsonData.timeCreated === '') {
        this.jsonData.timeCreated = new Date().toUTCString();
      }
      this.jsonData.timeLastModified = new Date().toUTCString();
      
      const notesSection = this.$refs.notesSection;

      if (notesSection) {
        const administeringNotes = notesSection.getAdministeringNotes();
        const scoringNotes = notesSection.getScoringNotes();

      if (this.jsonData) {
        if (administeringNotes) {
          this.jsonData.administeringNotes = administeringNotes;
        }

        if (scoringNotes) {
          this.jsonData.scoringNotes = scoringNotes;
        }
      }

        try {
          await this.controller.submitForm();
          this.notificationMessage = 'Notes Saved Successfully!';
        } catch (error) {
          this.notificationMessage = 'Saving notes failed. Please try again.';
          console.error('Error submitting form:', error);
        }
        finally {
          this.resetNotification();
        }
      } else {
        console.log('notesSection is undefined');
      }
    },
    resetNotification() {
      setTimeout(() => {
        this.notificationVisible = false;
      }, 3000);
    },
    async handleConfirmLeave() {
      this.showConfirmPopup = false;
      await this.submitForm();
      this.$router.push(this.nextRoute);
    },
    handleNoSaveLeave() {
      this.showConfirmPopup = false;
      this.forceNavigate = true;
      this.$router.push(this.nextRoute);
    }
  },
  beforeRouteLeave(to, from, next) {
    const notesSection = this.$refs.notesSection;
    const currentAdministeringNotes = notesSection.getAdministeringNotes();
    const currentScoringNotes = notesSection.getScoringNotes();

    if (this.forceNavigate) {
      this.forceNavigate = false;
      next();
      return;
    }

    if ( 
      (!this.isScoring && currentAdministeringNotes !== this.jsonData.administeringNotes) || 
      (this.isScoring && currentScoringNotes !== this.jsonData.scoringNotes) 
    ) {
      this.showConfirmPopup = true;
      this.nextRoute = to.fullPath;
      next(false);
    } else {
      this.controller.model.saveData(this.jsonData);
      next();
    }
  }
};
</script>
