<template>
    <div class="input-field">
        <input
            type="text"
            :placeholder="input.name === 'dob' ? 'MM/DD/YYYY' : 'Type Here'"
            :id="input.name"
            v-model="textInput"
            @input="handleTextInput"
        />
        <TextButton
            v-if="input.name === 'startTime'"
            class="start-time"
            type="button"
            buttonText="Set Current Time"
            @click="setCurrentTime"
        />
        <span v-if="input.name === 'dob' && !isValidDob" class="error-message">Please enter a valid date (MM/DD/YYYY)</span>
        <span v-if="input.name === 'birthYear' && !isMatchingYear" class="error-message">The inputted year does not match the participant's birth year.</span>
        <span v-if="input.name === 'birthYear' && isMatchingYear" class="success-message">The inputted year matches the participant's birth year.</span>
        <span v-if="input.name === 'birthMonth' && !isMatchingMonth" class="error-message">The inputted month does not match the participant's birth month.</span>
        <span v-if="input.name === 'birthMonth' && isMatchingMonth" class="success-message">The inputted month matches the participant's birth month.</span>
        <span v-if="input.name === 'birthDay' && !isMatchingDay" class="error-message">The inputted day does not match the participant's birth day.</span>
        <span v-if="input.name === 'birthDay' && isMatchingDay" class="success-message">The inputted day matches the participant's birth day.</span>
        <span v-if="input.name === 'age' && !isMatchingAge" class="error-message">The inputted age does not match the participant's age.</span>
        <span v-if="input.name === 'age' && isMatchingAge" class="success-message">The inputted age matches the participant's age.</span>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs } from 'vue';
import TextButton from '../TextButton.vue';
import CASIController from '../../controllers/CASIController';

const casiController = new CASIController();

export default defineComponent({
    props: {
        input: {
            type: Object,
            required: true
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    components: { 
        TextButton 
    },
    setup(props, { emit }) {
        const { input } = toRefs(props);
        const valueNode = input._object.input['value']
        let currValue = ''
        if (valueNode != null){
            currValue = valueNode['enteredText']
        }
        const isValidDob = ref(true);
        const isMatchingYear = ref(false);
        const isMatchingMonth = ref(false);
        const isMatchingDay = ref(false);
        const isMatchingAge = ref(false);

        const textInput = ref('');
        if (input.value.name === 'startTime' && input.value.value.enteredText) {
            const date = new Date(input.value.value.enteredText);
            textInput.value = date.toLocaleTimeString();
        } else {
            textInput.value = currValue;
        }
        
        let debounceTimeout = null;

        function handleTextInput(event) {
            const value = event.target.value;
            textInput.value = value;
            emit('input-changed');

            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            if (props.input.name === 'dob') {
                isValidDob.value = validateDob(value);
                if (isValidDob.value) {
                    const [month, day, year] = value.split('/');
                    const monthNum = convertMonthNameToNumber(month.trim());
                    casiController.setDob(monthNum, day, year);
                    console.log(`Extracted - Month: ${monthNum}, Day: ${day}, Year: ${year}`);
                }
            }

            if(props.input.name === 'ageQ') {
                casiController.setAge(value);
            }

            if (props.input.name === 'birthYear') {
                isMatchingYear.value = casiController.validateYear(value);
            }

            if (props.input.name === 'birthMonth') {
                isMatchingMonth.value = casiController.validateMonth(value);
            }

            if (props.input.name === 'birthDay') {
                isMatchingDay.value = casiController.validateDay(value);
            }

            if(props.input.name === 'age') {
                isMatchingAge.value = casiController.validateAge(value);
            }

            debounceTimeout = setTimeout(() => {
                console.log(`Text Input - Name: ${input.value.name}, Value: ${value}`);
                input.value.value.enteredText = value;
                console.log("Updated JSON:", input.value);
            }, 1000);
        }

        function convertMonthNameToNumber(monthName) {
            const months = {
                'january': 1, 'february': 2, 'march': 3, 'april': 4, 'may': 5, 'june': 6,
                'july': 7, 'august': 8, 'september': 9, 'october': 10, 'november': 11, 'december': 12
            };

            const monthLower = monthName.toLowerCase();
            const monthNum = months[monthLower];
            
            return monthNum || parseInt(monthName, 10);
        }

        function setCurrentTime() {
            const now = new Date();
            const localTimeString = now.toLocaleTimeString();
            const utcString = now.toUTCString();
            textInput.value = localTimeString;
            input.value.value.enteredText = utcString;
        }

        function validateDob(value) {
            const dobRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
            return dobRegex.test(value);
        }

        return {
            textInput,
            handleTextInput,
            isValidDob,
            isMatchingYear,
            isMatchingMonth,
            isMatchingDay,
            isMatchingAge,
            setCurrentTime
        };
    }
});
</script>

<style scoped>
.start-time {
    margin-top: 10px;
    font-size: 14px;
    width: 40%;
}
.input-field input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    max-width: 400px;
    margin-top: 2px;
}
.input-field {
    width: 90%;
    max-width: 400px;
    margin-top: 2px;
}
input::placeholder {
    opacity: 0.5;
    font-size: 12px;
}
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}
.success-message {
    color: green;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}
</style>
