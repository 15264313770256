<template>
  <div class="casi">
    <BaseLayout 
      :heading="'CASI'" 
      :totalScore="totalScore" 
      :casiATT="casiATT"
      :casiMMC="casiMMC"
      :casiORI="casiORI"
      :casiLTM="casiLTM"
      :casiSTM="casiSTM"
      :casiLANG="casiLANG"
      :casiDRAW="casiDRAW"
      :casiFLU="casiFLU"
      :casiATAJ="casiATAJ"
    />
    <form @submit.prevent="submitForm">
      <CASIQuestions v-if="jsonData" :jsonData="jsonData" @score-added="handleScoreAdded" @input-changed="handleInputChanged" />
      <TextButton 
        class="submit-button" 
        buttonText="Submit" 
        :dark="true" 
        :disabled="isSubmitting"
        type="submit"
      ></TextButton>
    </form>
    <NotificationPopup 
      :message="notificationMessage" 
      :visible="notificationVisible"
    />
    <ConfirmationPopup
      :visible="showConfirmPopup"
      @confirm="handleConfirmLeave"
      @noSave="handleNoSaveLeave"
      @cancel="showConfirmPopup = false"
    />
  </div>
</template>

<script>
import CASIQuestions from '../components/CASI/CASIQuestions.vue';
import BaseLayout from '../views/BaseLayout.vue';
import CASIController from '../controllers/CASIController';
import TextButton from '../components/TextButton.vue';
import NotificationPopup from '../components/NotificationPopup.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';

export default {
  components: { 
    CASIQuestions,
    BaseLayout,
    TextButton,
    NotificationPopup,
    ConfirmationPopup
  },
  data() {
    return {
      controller: new CASIController(),
      jsonData: null,
      totalScore: null,
      casiATT: null,
      casiMMC: null,
      casiORI: null,
      casiLTM: null,
      casiSTM: null,
      casiLANG: null,
      casiDRAW: null,
      casiFLU: null,
      casiATAJ: null,
      notificationMessage: '',
      notificationVisible: false,
      isSubmitting: false,
      hasUnsavedChanges: false,
      showConfirmPopup: false,
      nextRoute: null,
      forceNavigate: false
    };
  },
  async mounted() {
    await this.loadData();
    this.calculateScores();
  },
  methods: {
    async loadData() {
      await this.controller.loadData();
      this.jsonData = this.controller.model.data;
    },
    async submitForm() {
      console.log('Submit form called');
      
      this.notificationMessage = 'Submitting...';
      this.notificationVisible = true;
      this.isSubmitting = true;
      
      if(this.jsonData.timeCreated === '') {
        this.jsonData.timeCreated = new Date().toUTCString();
      }
      this.jsonData.timeLastModified = new Date().toUTCString();

      try {
        await this.controller.submitForm();
        this.hasUnsavedChanges = false;
        this.notificationMessage = 'CASI Submitted Successfully!';
      } catch (error) {
        this.notificationMessage = 'CASI submit failed. Please try again.';
      } finally {
        this.calculateScores();
        this.resetNotification();
        this.isSubmitting = false;
      }
    },
    handleScoreAdded() {
      this.calculateScores();
      this.hasUnsavedChanges = true;
    },
    handleInputChanged() {
      console.log("hasUnsavedChanges" + this.hasUnsavedChanges);
      this.hasUnsavedChanges = true;
      console.log("hasUnsavedChanges" + this.hasUnsavedChanges);
    },
    calculateScores() {
      this.controller.calculateScores();
      this.updateScores();
    },
    updateScores() {
      this.totalScore = this.controller.totalScore;
      this.casiATT = this.controller.casiATT;
      this.casiMMC = this.controller.casiMMC;
      this.casiORI = this.controller.casiORI;
      this.casiLTM = this.controller.casiLTM;
      this.casiSTM = this.controller.casiSTM;
      this.casiLANG = this.controller.casiLANG;
      this.casiDRAW = this.controller.casiDRAW;
      this.casiFLU = this.controller.casiFLU;
      this.casiATAJ = this.controller.casiATAJ;
    },
    resetNotification() {
      setTimeout(() => {
        this.notificationVisible = false;
      }, 5000);
    },
    async handleConfirmLeave() {
      this.showConfirmPopup = false;
      this.hasUnsavedChanges = false;
      await this.submitForm();
      this.$router.push(this.nextRoute);
    },
    handleNoSaveLeave() {
      this.showConfirmPopup = false;
      this.forceNavigate = true;
      this.hasUnsavedChanges = false;
      this.$router.push(this.nextRoute);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.forceNavigate) {
      this.forceNavigate = false;
      next();
      return;
    }

    if (this.hasUnsavedChanges) {
      this.showConfirmPopup = true;
      this.nextRoute = to.fullPath;
      next(false);
    } else {
      this.controller.model.saveData(this.jsonData);
      next();
    }
  }
};
</script>

<style>
.casi {
  max-width: 1200px;
  margin: auto;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.section {
  padding-bottom: 10px;
}

.submit-button {
  margin: 30px auto 0;
}

.instructions {
  margin-bottom: 30px;
  color: #555;
}

.introduction {
  margin-bottom: 20px;
  color: #555;
}

.notes {
  margin-top: 30px;
}
</style>
