<template>
    <div class="input-scores">
        <div v-if="input.shownScoreLabel" class="score-label">
            {{ input.shownScoreLabel }}
        </div>
        
        <div
            v-for="(score, scoreIndex) in input.scores"
            :key="`score-${scoreIndex}`"
            class="choice-option"
            :ref="(el) => (checkboxes[scoreIndex] = el)" 
        >
            <div class="checkbox-container">
                <input
                    ref="checkbox" 
                    type="checkbox"
                    class="checkboxButtons"
                    :tabindex="0"
                    :id="`score-${scoreIndex}`"
                    :name="input.name"
                    :value="score.value"
                    :checked="isChecked(score.value)"
                    @change="handleCheckboxChange(score.value)"
                    @keydown="handleKeyDown($event, scoreIndex)"
                />
                <label :for="`score-${scoreIndex}`" class="choice-label">
                    {{ score.value !== null ? score.value : '' }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
    props: {
        input: {
            type: Object,
            required: true
        },
        modelValue: {
            type: [String, Number],
            default: null
        }
    },
    setup(props, { emit }) {
        const { input } = toRefs(props);

        const checkboxes = ref([]);
        const highestValue = ref(props.modelValue);

        watch(() => props.modelValue, (newValue) => {
            highestValue.value = newValue;
        }, { immediate: true });

        function isChecked(value) {
            return highestValue.value === value;
        }

        function handleCheckboxChange(value) {
            if (highestValue.value === value) {
                highestValue.value = null;
            } else {
                highestValue.value = value;
            }

            input.value.value.selectedOption = highestValue.value;
            emit('score-added', input.value.scoreLabel, highestValue.value);
            console.log(`ScoreLabel: ${input.value.scoreLabel}, Selected Score: ${highestValue.value}`);
        }

        function handleKeyDown(event, index) {
            if (event.key === 'ArrowRight') {
                moveFocus(index + 1);
                event.preventDefault();
            } else if (event.key === 'ArrowLeft') {
                moveFocus(index - 1);
                event.preventDefault();
            }
        }

        function moveFocus(newIndex) {
            const checkboxesArray = checkboxes.value;
            if (newIndex >= 0 && newIndex < checkboxesArray.length) {
                checkboxesArray[newIndex].querySelector('input').focus();
            }
        }

        return {
            highestValue,
            isChecked,
            handleCheckboxChange,
            handleKeyDown,
            checkboxes
        };
    }
});
</script>

<style scoped>
.score-label {
    font-weight: bold;
    margin-right: 30px;
}

.input-scores {
    display: flex;
    flex-wrap: wrap;
}

.choice-option {
    display: flex;
    align-items: center;
    margin-right: 24px;
}

.checkbox-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.checkboxButtons {
    accent-color: #237091;
}

.choice-label {
    margin-top: 8px;
}
</style>
