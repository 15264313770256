<template>
    <div class="timer">
        <div class="timer-buttons">
            <TextButton
                v-if="!isTiming && timer === 30"
                @click="startTimer"
                :buttonText="'Start Timer'"
            />
            <TextButton
                v-if="isTiming"
                @click="pauseTimer"
                :buttonText="'Pause'"
            />
            <TextButton
                v-if="!isTiming && timer < 30 && timer > 0"
                @click="startTimer"
                :buttonText="'Resume'"
            />
            <TextButton
                @click="resetTimer"
                :buttonText="'Reset'"
            />
        </div>

        <p v-if="timer > 0">{{ timer }} seconds remaining</p>
    </div>
</template>

<script>
import TextButton from '../TextButton.vue';

export default {
    components: { TextButton },
    data() {
        return {
            timer: 30,
            timerInterval: null,
            isTiming: false
        };
    },
    methods: {
        startTimer() {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
            this.isTiming = true;
            this.timerInterval = setInterval(() => {
                if (this.timer > 0) {
                    this.timer--;
                } else {
                    this.isTiming = false;
                    clearInterval(this.timerInterval);
                }
            }, 1000);
        },
        pauseTimer() {
            this.isTiming = false;
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
        },
        resetTimer() {
            this.pauseTimer();
            this.timer = 30;
        }
    },
    beforeUnmount() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }
};
</script>

<style scoped>
.timer {
  margin-top: 25px;
  margin-bottom: 35px;
}

.timer-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.timer p {
  font-size: 18px;
  margin-top: 10px;
  color: #333;
}
</style>
