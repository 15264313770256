<template>
    <div>
        <div v-if="recallQuestions.length > 0" class="recall-container">
            <div v-for="(question, index) in recallQuestions" :key="index" class="section recall-question">
                <CASIQuestion
                    :question="question"
                    :section="sectionType === 'post'"
                    @score-added="handleScoreAdded"
                    @input-changed="$emit('input-changed')"
                />
                <CASINoteSection v-if="question.inputs.some(input => ['recallQ'].includes(input.name))"/>
            </div>
        </div>

        <div v-for="(question, index) in otherQuestions" :key="index" class="section">
            <CASIQuestion
                :question="question"
                :section="sectionType === 'post'"
                @score-added="handleScoreAdded"
                @input-changed="$emit('input-changed')"
            />
        </div>

        <ScoreLabelSection v-if="sectionType !== 'pre' && sectionType !== 'post'" :scores="scoreLabels" />

        <CASINoteSection v-if="!sectionHasTextInput" />

        <div class="grey-line"></div>
    </div>
</template>

<script>
import CASINoteSection from '../CASINoteSection.vue';
import ScoreLabelSection from '../ScoreLabelSection.vue';
import CASIQuestion from './CASIQuestion.vue';

export default {
    components: { 
        CASIQuestion,
        ScoreLabelSection,
        CASINoteSection
    },
    props: {
        questions: Array,
        sectionType: String
    },
    computed: {
        scoreLabels() {
            const scoreMap = {};

            this.questions.forEach(question => {
                question.inputs.forEach(input => {
                    if (input.shownScoreLabel && input.scores) {
                        const selectedScore = input.scores.find(score => score.value === input.value.selectedOption);
                        const scoreValue = selectedScore ? selectedScore.value : 0;

                        if (scoreMap[input.shownScoreLabel]) {
                            scoreMap[input.shownScoreLabel] += scoreValue;
                        } else {
                            scoreMap[input.shownScoreLabel] = scoreValue;
                        }
                    }
                });
            });

            return Object.keys(scoreMap).map(label => ({
                label,
                score: scoreMap[label]
            }));
        },
        sectionHasTextInput() {
            return this.questions.some(question =>
                question.inputs.some(input => input.type === 'text')
            );
        },
        recallQuestions() {
            return this.questions.filter(question =>
                question.inputs.some(input => ['recallQ', 'recall'].includes(input.name))
            );
        },
        otherQuestions() {
            return this.questions.filter(question =>
                !question.inputs.some(input => ['recallQ', 'recall'].includes(input.name))
            );
        }
    },
    methods: {
        handleScoreAdded(scoreLabel, value) {
            this.$emit('score-added', scoreLabel, value);
        }
    }
};
</script>

<style scoped>
.grey-line {
  border-bottom: 2px solid darkgray;
  margin-top: 50px;
  margin-bottom: 25px;
}

.recall-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.recall-question {
  flex: 1;
  margin-right: 20px;
}

.recall-question:last-child {
  margin-right: 0;
}
</style>