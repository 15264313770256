<template>
    <BaseLayout :heading="'Trail Making A'" />

    <div class="instructions"> 
        <div class="header">Practice A</div>
        <div class="line">OK, <b>Press the continue button.</b> On this screen, you’ll see some numbers. I will want you to draw a line in numerical order. So you would begin at 1, draw a line from 1 to 2, 2 to 3, 3 to 4, as the screen is showing you now, but you will continue on all the way to 8. Press the continue button. Here you will see that the first line is a mistake from 1 to 4. When that happens, you will need to restart at the last correct number and draw a line to the next correct one. Do you understand? OK, <b>Press the continue button and begin.</b></div>
        <div class="line">(Wait for them to finish).</div>
        <div class="last-line">Great, that was practice. <b>Now press the continue button.</b></div>

        <div class="header">Task A</div>
        <div class="line">On the next screen you will see more numbers, and you will do exactly the same thing. You will connect the circles in numerical order. You will go from 1-2, 2-3, 3-4 and so on until you reach 25. I need you to work as quickly as you can but without making an error. <b>Press the continue button and begin.</b></div>
        <div class="line">(Wait for them to finish).</div>
        <div class="line"><b>Now press the continue button.</b></div>
    </div>

    <NotesSection 
      :administeringNotes="jsonData ? jsonData.administeringNotes : ''" 
      :scoringNotes="jsonData ? jsonData.scoringNotes : ''" 
      ref="notesSection" />
    
    <TextButton buttonText="Submit" :dark="true" type="submit" @click="submitForm" />
  
    <NotificationPopup 
      :message="notificationMessage" 
      :visible="notificationVisible"
    />

    <ConfirmationPopup
      :visible="showConfirmPopup"
      @confirm="handleConfirmLeave"
      @noSave="handleNoSaveLeave"
      @cancel="showConfirmPopup = false"
    />
</template>
  
<script>
import BaseLayout from '../BaseLayout.vue';
import NotesSection from '../../components/NotesSection.vue';
import TaskController from '../../controllers/TaskController.js';
import ServerCodeModel from '../../models/ServerCodeModel';
import TextButton from '@/components/TextButton.vue';
import NotificationPopup from '@/components/NotificationPopup.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';  

export default {
  components: {
      BaseLayout,
      NotesSection,
      TextButton,
      NotificationPopup,
      ConfirmationPopup
  },
  data() {
    return {
      controller: null,
      jsonData: null,
      notificationMessage: '',
      notificationVisible: false,
      showConfirmPopup: false,
      nextRoute: null,
      forceNavigate: false
    };
  },
  computed: {
    isAdministering() {
        return this.$route.query.selection === 'administer';
    }
  },
  async mounted() {
    const serverCodeModel = new ServerCodeModel();
    serverCodeModel.logType = 'InterviewerTrailMakingALog';

    this.controller = new TaskController(serverCodeModel.logType, 'trailMakingAData', serverCodeModel);

    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.controller.loadData();
      this.jsonData = this.controller.model.data;
    },
    async submitForm() {
      this.notificationMessage = 'Saving...';
      this.notificationVisible = true;

      if(this.jsonData.timeCreated === '') {
        this.jsonData.timeCreated = new Date().toUTCString();
      }
      this.jsonData.timeLastModified = new Date().toUTCString();

      const notesSection = this.$refs.notesSection;

      if (notesSection) {
        const administeringNotes = notesSection.getAdministeringNotes();
        const scoringNotes = notesSection.getScoringNotes();

      if (this.jsonData) {
        if (administeringNotes) {
          this.jsonData.administeringNotes = administeringNotes;
        }

        if (scoringNotes) {
          this.jsonData.scoringNotes = scoringNotes;
        }
      }

        try {
          await this.controller.submitForm();
          this.notificationMessage = 'Notes Saved Successfully!';
        } catch (error) {
          this.notificationMessage = 'Saving notes failed. Please try again.';
          console.error('Error submitting form:', error);
        }
        finally {
          this.resetNotification();
        }
      } else {
        console.log('notesSection is undefined');
      }
    },
    resetNotification() {
      setTimeout(() => {
        this.notificationVisible = false;
      }, 3000);
    },
    async handleConfirmLeave() {
      this.showConfirmPopup = false;
      await this.submitForm();
      this.$router.push(this.nextRoute);
    },
    handleNoSaveLeave() {
      this.showConfirmPopup = false;
      this.forceNavigate = true;
      this.$router.push(this.nextRoute);
    }
  },
  beforeRouteLeave(to, from, next) {
    const notesSection = this.$refs.notesSection;
    const currentAdministeringNotes = notesSection.getAdministeringNotes();
    const currentScoringNotes = notesSection.getScoringNotes();

    if (this.forceNavigate) {
      this.forceNavigate = false;
      next();
      return;
    }

    if ( 
      (this.isAdministering && currentAdministeringNotes !== this.jsonData.administeringNotes) || 
      (!this.isAdministering && currentScoringNotes !== this.jsonData.scoringNotes) 
    ) {
      this.showConfirmPopup = true;
      this.nextRoute = to.fullPath;
      next(false);
    } else {
      this.controller.model.saveData(this.jsonData);
      next();
    }
  }
};
</script>

<style scoped>
.instructions {
    font-size: 18px;
}
.header {
    font-weight: bold;
    text-decoration: underline;
}
.line {
    margin-bottom: 20px;
}
.last-line {
    margin-bottom: 40px;
}
</style>