<template>
    <div class="input-scores">
        <div v-if="input.shownScoreLabel" class="score-label">
            {{ input.shownScoreLabel }}
        </div>
        <div
            v-for="(score, scoreIndex) in input.scores"
            :key="`score-${scoreIndex}`"
            class="choice-option"
        >
            <input
                type="radio"
                class="radioButtons"
                :id="`score-${scoreIndex}`"
                :name="input.name"
                :value="score.value"
                v-model="radioSelection"
                @change="emitRadioChange"
            />
            <label :for="`score-${scoreIndex}`" class="choice-label">
                {{ (score.value !== null ? score.value : '') + (score.value !== null && score.label ? ': ' : '') + (score.label ? score.label : '') }}
            </label>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
    props: {
        input: {
            type: Object,
            required: true
        },
        modelValue: {
            type: [String, Number, Object],
            default: ''
        }
    },
    setup(props) {
        const { input } = toRefs(props);

        const valueNode = input.value?.value;
        let currValue = null;

        if (valueNode) {
            currValue = valueNode.selectedOption || props.modelValue;
        }

        const radioSelection = ref(currValue);

        function emitRadioChange() {
            const value = radioSelection.value;
            console.log(`Radio Change - Name: ${input.value.name}, Value: ${value}`);
            input.value.value.selectedOption = value;

            this.$emit('score-added', input.value.scoreLabel, value);

            console.log(`ScoreLabel: ${input.value.scoreLabel}, Selected Score: ${value}`);
        }

        return {
            radioSelection,
            emitRadioChange
        };
    }
});
</script>

<style scoped>
.choice-label {
    margin-left: 8px;
    font-size: 14px;
}

.radioButtons {
    accent-color: #237091;
}

.score-label {
    font-weight: bold;
}
</style>
